<template>
  <div>
    <b-card class="mx-auto mt-5" style="max-width: 500px">
      <div class="logo mb-5">
        <img src="@/assets/logo.png" alt="logo" />
      </div>
      <b-form @submit="onSubmit">
        <b-form-group id="input-group-1" label="Username" label-for="input-1">
          <b-form-input
            id="input-1"
            v-model="form.username"
            placeholder="Enter username"
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-2" label="Password" label-for="input-2">
          <b-input-group>
            <b-form-input
              id="input-2"
              v-model="form.password"
              :type="showPassword ? 'text' : 'password'"
              placeholder="Enter password"
            ></b-form-input>
            <b-input-group-prepend is-text>
              <b-icon
                :icon="showPassword ? 'eye' : 'eye-slash'"
                @click="password"
              ></b-icon>
            </b-input-group-prepend>
          </b-input-group>
        </b-form-group>

        <div class="text-right">
          <router-link to="/home">
            <b-button type="submit" variant="primary">Sign in</b-button>
          </router-link>
        </div>
      </b-form>
    </b-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        username: '',
        password: ''
      },
      showPassword: false
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
    },

    password() {
      this.showPassword = !this.showPassword
    }
  }
}
</script>
